<template>
  <div>
    <!-- ANCHOR - Bộ lọc -->
    <b-card
      header-class="p-1"
      no-body
    >
      <template #header>
        <div
          class="w-100 d-flex justify-content-between cursor-pointer"
          @click="handleToggleShow"
        >
          <div class="d-flex align-items-center">
            <img
              :src="require('@icons/filters.svg')"
              alt="filters"
            >
            <span class="font-weight-bolder">
              Sort
            </span>
          </div>

          <IconSvg
            :src="require('@icons/triangle-down.svg')"
            blank-color="#ccc"
            class="rotate_class text-secondary"
            :style="{ transform: rotateStyleVisible, width: '12px' }"
          />
        </div>
      </template>

      <b-collapse
        v-model="isVisible"
        class="px-75 mb-1"
      >
        <!-- ANCHOR SẮP XẾP THEO -->
        <div>
          <h5>{{ $t('flight.sortBy') }}</h5>
          <v-select
            :value="sortBySelected"
            :reduce="val => val.value"
            label="label"
            :options="getSortClassBookingOptions"
            :clearable="false"
            @input="handleSelectSortBy"
          >
            <template #option="data">
              <small class="font-weight-bolder text-truncate">{{ data.label }}</small>
            </template>

            <template #selected-option="data">
              <small class="font-weight-bolder text-truncate">{{ data.label }}</small>
            </template>

            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </div>

        <!-- ANCHOR Hiện giá tổng (1 ADT) -->
        <!-- <div
          v-if="!['VU', '1G', 'VN1A'].includes(airlineSearch)"
          class="d-flex-center justify-content-start mt-75"
        >
          <BFormCheckbox
            :checked="showTotalPriceADT"
            class="custom-checkbox-label custom-control-warning"
            switch
            :disabled="['VU', '1G'].includes(airlineSearch)"
            @change="handleToggleShowTotalPriceADT"
          />
          <span>
            Hiện giá tổng (1 ADT)
          </span>
        </div> -->

        <!-- ANCHOR Hiện nối chuyến -->
        <div
          v-if="['1G'].includes(airlineSearch)"
          class="d-flex-center justify-content-start mt-75"
        >
          <BFormCheckbox
            :checked="showTransitFlight"
            class="custom-checkbox-label custom-control-warning"
            switch
            @change="handleToggleShowTransitFlight"
          >
            <span>
              {{ $t('flight.showTransitFlight') }}
            </span>
          </BFormCheckbox>
        </div>

        <!-- ANCHOR Hiện Pacific Airlines -->
        <div
          v-if="['VN'].includes(airlineSearch)"
          class="d-flex-center justify-content-start mt-75"
        >
          <BFormCheckbox
            :checked="showPacificAirlines"
            class="custom-checkbox-label custom-control-warning"
            switch
            @change="handleToggleShowPacificAirlines"
          />
          <span>
            Hiện Pacific Airlines
          </span>
        </div>

        <app-collapse class="mt-1">
          <!-- ANCHOR AIRLINE -->
          <app-collapse-item
            :title="$t('flight.airline')"
            is-visible
          >
            <div v-if="airlines.length">
              <b-form-group #default="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="sortAirlines"
                  :aria-describedby="ariaDescribedby"
                  name="airline-filter"
                  stacked
                >
                  <b-form-checkbox
                    v-for="(item, index) in airlines"
                    :key="index"
                    class="py-25 custom-control-warning"
                    :value="item.value"
                  >
                    <div class="w-100 d-flex-center justify-content-start">
                      <IAmLogoAirline
                        :airline="item.value.split(' | ')[0]"
                        :height="20"
                        custom-class="mr-25"
                      />
                      <span
                        style="line-height: 1.3rem;"
                        class="w-100"
                      >
                        <span class="font-weight-bold text-nowrap text-truncate">{{ item.text }}
                          <span class="ml-25 font-italic">({{ item.value.split(' | ')[0] }})</span>
                        </span>
                      </span>
                    </div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <div v-else>
              {{ $t('flight.loading') }}
            </div>
          </app-collapse-item>

          <!-- ANCHOR AIRCRAFT -->
          <app-collapse-item
            v-if="!['VN1A'].includes(airlineSearch)"
            :title="$t('flight.airCraft')"
          >
            <div v-if="airCrafts.length">
              <b-form-group #default="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="sortAirCrafts"
                  :aria-describedby="ariaDescribedby"
                  name="airline-filter"
                  stacked
                >
                  <b-form-checkbox
                    v-for="(airCraft, indexAirCraft) in airCrafts"
                    :key="indexAirCraft"
                    class="py-25 custom-control-warning"
                    :value="airCraft.value"
                  >
                    {{ airCraft.text === 'Unknown' ? $t(airCraft.text) : airCraft.text }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <div v-else>
              {{ $t('flight.loading') }}
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-collapse>
    </b-card>

    <!-- ANCHOR - Thời gian khởi hành -->
    <b-card
      header-class="p-1"
      body-class="px-1 py-0"
    >
      <template #header>
        <span class="font-weight-bolder">
          {{ $t("flight['Departure time']") }}
        </span>
      </template>

      <div>
        <div v-if="departureTime.length">
          <p class="d-flex justify-content-between align-items-center">
            <em class="text-muted">
              ({{ toHoursAndMinutes(min(departureTime)) }} - {{ toHoursAndMinutes(max(departureTime)) }})
            </em>
          </p>

          <template v-if="!isDisableFilterDeparture">
            <span class="font-weight-bold text-dark">
              {{ toHoursAndMinutes(sortDepartureTime[0]) }}
            </span>
            -
            <span class="font-weight-bold text-dark">
              {{ toHoursAndMinutes(sortDepartureTime[1]) }}
            </span>
          </template>

          <vue-slider
            :value="sortDepartureTime"
            :min="min(departureTime)"
            :min-range="10"
            :max="max(departureTime)"
            :dot-size="20"
            direction="ltr"
            :disabled="isDisableFilterDeparture"
            class="my-1 vue-slider-warning"
            :contained="true"
            :tooltip-formatter="toHoursAndMinutes"
            :lazy="true"
            :drag-on-click="true"
            @change="val => handleChangeSlide(val, 'sortDepartureTime')"
          />
        </div>
        <div v-else>
          {{ $t('flight.loading') }}
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormCheckbox, BCollapse, BFormGroup, BFormCheckboxGroup,
} from 'bootstrap-vue'
import {
  computed, ref, watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'

import { showPriceOptions } from '@/constants/selectOptions'
import store from '@/store'

import { toHoursAndMinutes } from '@core/utils/filter'

import useClassBookingHandle from '../useClassBookingHandle'

export default {
  components: {
    BCard,
    BCollapse,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    VueSlider,

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    vSelect,
  },
  setup() {
    const {
      min,
      max,
      isEmpty,
      debounce,
      cloneDeep,
      airlineSearch,
      isDisableFilterDeparture,
    } = useClassBookingHandle()

    const sortClassBookingOptions = [
      { label: 'Thời gian khởi hành', value: 'EARLIEST_DEPARTURE' },
      { label: 'Hãng hàng không', value: 'AIRLINE' },
      { label: 'Thời gian bay ngắn nhất', value: 'SHORTEST_DURATION' },
      { label: 'Thời gian bay dài nhất', value: 'LONGEST_DURATION' },
    ]

    const getSortClassBookingOptions = computed(() => {
      if (['VU'].includes(airlineSearch.value)) {
        return [{ label: 'Giá rẻ nhất', value: 'CHEAPEST_FARE' }, ...sortClassBookingOptions]
      }
      return sortClassBookingOptions
    })

    const sortDepartureTime = ref([0, 0])
    const sortAirCrafts = ref([])
    const sortAirlines = ref([])

    // ANCHOR Sort
    const sortBySelected = computed(() => store.getters['app-flight-v2/getSortItinerariesType'])
    // const showTotalPriceADT = computed(() => store.getters['app-flight-v2/getShowTotalPriceADT'])
    const showTransitFlight = computed(() => store.getters['app-flight-v2/getShowTransitFlight'])
    const showPacificAirlines = computed(() => store.getters['app-flight-v2/getShowPacificAirlines'])
    const departureTime = ref(computed(() => store.getters['app-flight-v2/getFilterDepartureTimeClassBooking']))
    const airCrafts = computed(() => store.getters['app-flight-v2/getFilterAirCrafts'])
    const airlines = computed(() => store.getters['app-flight-v2/getFilterAirlines'])

    function handleSelectSortBy(val) {
      store.dispatch('app-flight-v2/setSortItinerariesType', val)
    }

    watch(() => departureTime.value, val => {
      if (!isEmpty(val)) {
        sortDepartureTime.value[0] = min(val)
        sortDepartureTime.value[1] = max(val)
      }
    }, { deep: true, immediate: true })

    function sortDepartureTimeHandle(value) {
      store.dispatch('app/setLoading', true)
      setTimeout(async () => {
        await store.dispatch('app-flight-v2/setFilterByDepartureTimeClassBooking', cloneDeep(value))
        store.dispatch('app/setLoading', false)
      }, 200)
    }

    watch(() => sortDepartureTime.value, val => {
      if (!isEmpty(val)) {
        sortDepartureTimeHandle(val)
      }
    })

    // eslint-disable-next-line no-unused-vars, func-names
    const handleChangeSlide = debounce(function (val, dataName) {
      const times = val.every(t => Boolean(t))
      if (times) {
        this[dataName] = val
      }
    }, 100)

    // function handleToggleShowTotalPriceADT() {
    //   this.$bvModal.show('modal-api-loading')
    //   setTimeout(async () => {
    //     await store.dispatch('app-flight-v2/setShowTotalPriceADT', !showTotalPriceADT.value)
    //     this.$bvModal.hide('modal-api-loading')
    //   }, 200)
    // }

    function handleToggleShowTransitFlight() {
      this.$bvModal.show('modal-api-loading')
      setTimeout(async () => {
        await store.dispatch('app-flight-v2/setShowTransitFlight', !showTransitFlight.value)
        this.$bvModal.hide('modal-api-loading')
      }, 200)
    }

    function handleToggleShowPacificAirlines() {
      this.$bvModal.show('modal-api-loading')
      setTimeout(async () => {
        await store.dispatch('app-flight-v2/setShowPacificAirlines', !showPacificAirlines.value)
        this.$bvModal.hide('modal-api-loading')
      }, 200)
    }

    function sortAirCraftsHandle(value) {
      const timeoutId = setTimeout(() => {
        store.dispatch('app-flight-v2/setFilterByAirCrafts', value)
        clearTimeout(timeoutId)
      }, 100)
    }

    watch(() => sortAirCrafts.value, val => {
      sortAirCraftsHandle(cloneDeep(val))
    }, { deep: true })

    function sortAirlinesHandle(value) {
      const timeoutId = setTimeout(() => {
        store.dispatch('app-flight-v2/setFilterByAirlines', value)
        clearTimeout(timeoutId)
      }, 100)
    }

    watch(() => sortAirlines.value, val => {
      sortAirlinesHandle(cloneDeep(val))
    }, { deep: true })

    const isVisible = ref(true)
    const rotateStyleVisible = computed(() => isVisible.value ? 'rotate(0deg)' : 'rotate(-180deg)')
    const handleToggleShow = () => {
      isVisible.value = !isVisible.value
    }

    return {
      min,
      max,
      isDisableFilterDeparture,
      departureTime,
      // showTotalPriceADT,
      showTransitFlight,
      showPacificAirlines,
      showPriceOptions,
      getSortClassBookingOptions,
      sortDepartureTime,

      // handleToggleShowTotalPriceADT,
      handleToggleShowTransitFlight,
      handleToggleShowPacificAirlines,

      isVisible,
      rotateStyleVisible,
      handleToggleShow,
      toHoursAndMinutes,

      sortBySelected,
      handleSelectSortBy,

      airlineSearch,
      handleChangeSlide,

      airCrafts,
      sortAirCrafts,

      airlines,
      sortAirlines,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.rotate_class {
  transition: transform 0.3s ease;
}
.custom-checkbox-label::v-deep {
  .custom-control-label {
    margin-top: 2px !important;
  }
}
</style>
